<template>
  <v-container>
    <v-sheet class="pa-4">
      <v-row no-gutters align="center">
        <v-text-field
          v-model="search"
          label="Search Changelog"
          class="pt-4"
          dense
          outlined
          hide-details
          autocomplete="off"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialogAddChangeLog"
          width="600"
          persistent
          scrollable
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" depressed dark v-bind="attrs" v-on="on">
              Add ChangeLog
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              ADD CHANGELOG
              <v-spacer></v-spacer>
              <v-btn text @click="dialogAddChangeLog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-4">
              <v-form
                ref="addChangeLog"
                lazy-validation
                @submit.prevent="addChangeLog"
              >
                <v-row>
                  <v-col cols="12">
                    <DatePicker
                      label="Log Date"
                      :rules="[v => !!v || $t('app.required')]"
                      :value="form.date"
                      outlined
                      dense
                      @getValue="v => (form.date = v)"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="form.type"
                      :items="typeList"
                      :rules="[v => !!v || $t('app.required')]"
                      item-text="label"
                      item-value="value"
                      label="What is your role?"
                      clearable
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="form.developer"
                      :items="developerList"
                      :rules="[v => !!v || $t('app.required')]"
                      item-text="label"
                      item-value="value"
                      label="Who are you dude?"
                      clearable
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex">
                      <span
                        v-if="setLogoRole()"
                        class="mr-2 primary--text font-weight-black display-1"
                        >{{ setLogoRole() }}</span
                      >
                      <v-autocomplete
                        v-model="form.version_type"
                        :items="versionTypeList"
                        :rules="[v => !!v || $t('app.required')]"
                        item-text="label"
                        item-value="value"
                        label="Version Type"
                        clearable
                        outlined
                        dense
                      />
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="12" v-for="(ch, i) in changelogs" :key="i">
                        <v-row no-gutters>
                          <v-text-field
                            v-model="ch.text"
                            label="Your Changelog"
                            :rules="[v => !!v || $t('app.required')]"
                            dense
                            autocomplete="off"
                            outlined
                          ></v-text-field>
                          <v-btn
                            v-if="i + 1 == changelogs.length"
                            color="primary"
                            class="ml-2"
                            :disabled="!changelogs[i].text"
                            @click="addLog(i)"
                            ><v-icon>mdi-plus-box</v-icon></v-btn
                          >
                          <v-btn
                            v-else
                            color="error"
                            class="ml-2"
                            @click="deleteLog(i)"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                color="primary"
                :loading="loadingAdd"
                depressed
                block
                @click="addChangeLog"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-tabs v-model="tab" class="mt-5" @change="getChangeLogHandler()">
        <v-tab>
          <span class="font-weight-black"
            >BACKEND {{ datas.length > 0 ? datas[0].version : "" }}</span
          >
        </v-tab>
        <v-tab>
          <span class="font-weight-black"
            >FRONTEND {{ datas.length > 0 ? datas[0].version : "" }}</span
          >
        </v-tab>
        <v-tab>
          <span class="font-weight-black"
            >MOBILE {{ datas.length > 0 ? datas[0].version : "" }}</span
          >
        </v-tab>
      </v-tabs>

      <v-data-table
        :headers="headers"
        :items="datas"
        :search="search"
        :loading="loading"
        :items-per-page="10"
        show-expand
        class="elevation-0 mt-3"
      >
        <template v-slot:[`item.version`]="{ item }">
          <strong
            >{{ item.version_major }}.{{ item.version_minor }}.{{
              item.version_patch
            }}</strong
          >
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | dateFormat }}
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length" class="pa-2 body-1">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(c, i) in item.changelogs" :key="i">
                    <td style="width: 35px">{{ i + 1 }}.</td>
                    <td>
                      {{ c }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
import moment from "moment/moment";
moment.locale("en");

import { getChangeLog, addChangeLog } from "@/api/app";

export default {
  components: {
    DatePicker: () => import("@/components/DatePicker")
  },
  filters: {
    dateFormat: value => {
      if (!value) return "-";
      return moment(value).format("DD MMMM YYYY");
    }
  },
  data() {
    return {
      tab: 0,
      headers: [
        {
          text: "Version",
          align: "start",
          value: "version"
        },
        { text: "Date", value: "date" },
        { text: "Developer", value: "developer" }
      ],
      datas: [],
      developerList: [
        {
          label: "Agung",
          value: "AGUNG"
        },
        {
          label: "Nixon",
          value: "NIXON"
        },
        {
          label: "Anung",
          value: "ANUNG"
        },
        {
          label: "Gilang",
          value: "GILANG"
        },
        {
          label: "Abraham",
          value: "ABRAHAM"
        },
        {
          label: "Aji",
          value: "AJI"
        },
        {
          label: "Yepi",
          value: "YEPI"
        },
        {
          label: "Jeffri",
          value: "JEFFRI"
        },
        {
          label: "Candra",
          value: "CANDRA"
        }
      ],
      typeList: [
        {
          label: "BACKEND HERO",
          value: "BACKEND"
        },
        {
          label: "FRONTEND HERO",
          value: "FRONTEND"
        },
        {
          label: "MOBILE HERO",
          value: "MOBILE"
        }
      ],
      versionTypeList: [
        {
          label: "PATCH",
          value: "PATCH"
        },
        {
          label: "MINOR",
          value: "MINOR"
        },
        {
          label: "MAJOR",
          value: "MAJOR"
        }
      ],
      search: "",
      dialogAddChangeLog: false,
      loadingAdd: false,
      changelogs: [
        {
          text: ""
        }
      ],
      loading: false,
      form: {
        version_type: "",
        date: moment().format("YYYY-MM-DD"),
        developer: "",
        type: "",
        changelogs: []
      }
    };
  },
  mounted() {
    this.getChangeLogHandler();
  },
  methods: {
    getChangeLogHandler() {
      this.datas = [];
      this.loading = true;
      let role = "";
      switch (this.tab) {
        case 0:
          role = "BACKEND";
          break;
        case 1:
          role = "FRONTEND";
          break;
        default:
          role = "MOBILE";
          break;
      }

      this.form.type = role;
      getChangeLog(role)
        .then(res => {
          if (res.data.code) {
            this.datas = res.data.data;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    setLogoRole() {
      let roles = "";
      if (this.form.type == "BACKEND") {
        roles = "B";
      } else if (this.form.type == "FRONTEND") {
        roles = "F";
      } else if (this.form.type == "MOBILE") {
        roles = "M";
      } else {
        roles = "";
      }
      return roles;
    },
    addChangeLog() {
      if (this.$refs.addChangeLog.validate()) {
        this.loadingAdd = true;
        const queryData = JSON.parse(JSON.stringify(this.form));
        this.changelogs.map(r => queryData.changelogs.push(r.text));
        addChangeLog(queryData)
          .then(async res => {
            if (res.data.code) {
              this.form = {
                version_type: "",
                date: moment().format("YYYY-MM-DD"),
                developer: "",
                type: "",
                changelogs: []
              };
              this.changelogs = [
                {
                  text: ""
                }
              ];

              this.getChangeLogHandler();
              this.dialogAddChangeLog = false;
              this.$store.commit("CALL_SNACKBAR", {
                msg: "Success add changelog! Have a great work, Thankyou Guys.",
                color: "success"
              });
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loadingAdd = false;
          })
          .catch(() => (this.loadingAdd = false));
      }
    },
    addLog() {
      this.changelogs.push({
        text: ""
      });
    },
    deleteLog(i) {
      this.changelogs.splice(i, 1);
    }
  }
};
</script>
